// ProtectedRoute.jsx
import React from "react";
import { Route, Redirect } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; // Ensure correct import

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = () => {
        const user = JSON.parse(localStorage.getItem("userData"));

        // Check if the user's role is "5"
        if (user?.role !== "5") {
            return false;
        }

        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
            return false; // No token present
        }

        // Validate JWT structure
        const parts = authToken.split(".");
        if (parts.length !== 3) {
            return false; // Not a valid JWT structure
        }

        // Decode the token
        let decodedToken;
        try {
            decodedToken = jwtDecode(authToken);
        } catch (error) {
            return false; // Token is invalid
        }

        // Check if the token is expired
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (decodedToken.exp < currentTime) {
            return false; // Token is expired
        }

        return true; // User is authenticated
    };

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    );
};

export default ProtectedRoute;
