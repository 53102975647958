import { useFormikContext } from "formik";
import { useEffect, useCallback } from "react";
import { CalculeTTC } from "../../../utilities";

const AutoCalculateTTC = () => {
  const { values, setFieldValue } = useFormikContext();

  const calculateTTC = useCallback(() => {
    const montantCommande = parseFloat(values.MontantCommande) || 0;
    const livraisonHT = parseFloat(values.livraisonHT) || 0;
    const commission = parseFloat(values.commission) || 0;
    const tva = parseFloat(values.TVA) || 20;

    return CalculeTTC(montantCommande, livraisonHT, commission, tva);
  }, [values.MontantCommande, values.livraisonHT, values.commission, values.TVA]);

  useEffect(() => {
    const montantTTC = calculateTTC();
    setFieldValue("TTC", montantTTC.toFixed(2));
  }, [calculateTTC, setFieldValue]);

  return null;
};

export default AutoCalculateTTC;
