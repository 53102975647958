import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import NotFound from "./pages/NotFound";
import Layout from "./components/Layout";
//Authentication route
import Log_in from "./pages/Authentication/LoginScreen";
import resetPassword from "./pages/Authentication/ResetPasswordScreen"
import changePassword from "./pages/Authentication/ChangePasswordScreen";

//User route
import ListScreen from "./pages/users/ListScreen";
import EditScreen from "./pages/users/EditScreen"
import ShowScreen from "./pages/users/ShowScreen"
//Products route
import Product_ListScreen from "./pages/Proucts/ListScreen";
import Product_EditScreen from "./pages/Proucts/EditScreen";
import Product_AddScreen from "./pages/Proucts/AddScreen";
//Categories route
import Categories_ListScreen from "./pages/Categories/ListScreen";
//Brands route
import Brands_ListScreen from "./pages/Brands/ListScreen";
//Deliveries route
import Deliveries_ListScreen from "./pages/Deliveries/ListScreen";
import zone_showScreen from "./pages/Deliveries/showScreen"
//Orders route
import Orders_ListScreen from "./pages/orders/ListScreen";
import Orders_showScreen from "./pages/orders/ShowScreen";
//Fournisseur
import fournisseurs_listScreen from "./pages/Fournisseur/CompteFournisseur/ListFournisseurs.jsx"
import fournisseur_showScreen from "./pages/Fournisseur/CompteFournisseur/ShowFournisseur.jsx";
import fournisseur_addScreen from "./pages/Fournisseur/CompteFournisseur/AddFournisseur.jsx";

//Orders de fournisseur route
import Orders_AllFournisseurs_commandes from "./pages/Fournisseur/CommandesTousFournisseurs/ListCommandesAllFournisseur.jsx";
import Orders_Fournisseurs_ShowScreen from "./pages/Fournisseur/CommandesFournisseurs/ShowCommandeFournisseur";
import Orders_Fournisseurs_EditScreen from "./pages/Fournisseur/CommandesFournisseurs/EditCommandeFournisseur.jsx";
import Orders_Fournisseurs_AddScreen from "./pages/Fournisseur/CommandesFournisseurs/AddCommandeFournisseur.jsx";
import FirstConnexion from "./pages/Fournisseur/connexionSupplier/firstConnexion.jsx";
//Deliveries route
import DeliveryTower_ListScreen from "./pages/DeliveryTower/ListScreen";
// code Promo route
import CodePromo_ListScreen from "./pages/CodePromo/ListScreen"
//  utilisateur
import User_AddScreen from "./pages/users/AddScreen"
import FormSupplier from "./pages/Fournisseur/demandeCommandeFournisseurs/orderSupplier.jsx";
import FormSupplierConfirm from "./pages/Fournisseur/demandeCommandeFournisseurs/validationOrder.jsx";
// toaster
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from './utils/ProtectedRoute.jsx'
import FournisseurRoute from "./utils/FournisseurRoute.jsx";

function Routes() {
    return (
        <BrowserRouter>
            <ToastContainer />
            <Switch>
                <Route path="/" exact component={Log_in} /> 
                <Route path="/login" component={Log_in} /> {/* Login route */}
                <Route path="/fournisseur/renitialise-mot-de-passe/:token" component={FirstConnexion} /> {/*first connexion fourniseeur */}
                <Route path="/reinitialiser_mot_de_passe" component={resetPassword} /> {/* Reset Password Route */}
                <Route path="/changer_mot_de_passe" component={changePassword} /> {/* Change Password Route */}
                <FournisseurRoute path="/fournisseur/commande" component={FormSupplier} />
                <FournisseurRoute path="/fournisseur/confirmation" component={FormSupplierConfirm} />

                <Route render={(props) => (
                    <Layout {...props}>
                        <Switch>
                            {/* DASHBOARD */}
                            <ProtectedRoute path="/" exact component={Dashboard}  />
                            <ProtectedRoute path="/dashboard" exact component={Dashboard}/>
                            {/* USERS */}
                            <ProtectedRoute path="/users" component={ListScreen} />
                            <ProtectedRoute path="/user/edit/:id" component={EditScreen} />
                            <ProtectedRoute path="/user/show/:id" component={ShowScreen} />
                            <ProtectedRoute path="/user/add" component={User_AddScreen} />
                            {/* PRODUCTS */}
                            <ProtectedRoute path="/products" component={Product_ListScreen} />
                            <ProtectedRoute path="/product/edit/:productId" component={Product_EditScreen} />
                            <ProtectedRoute path="/product/add/" component={Product_AddScreen} />
                            {/* CATEGORIES */}
                            <ProtectedRoute path="/categories" component={Categories_ListScreen} />
                            {/* BRANDS */}
                            <ProtectedRoute path="/brands" component={Brands_ListScreen} />
                            {/* DELIVERIES */}
                            <ProtectedRoute path="/deliveries" component={Deliveries_ListScreen} />
                            <ProtectedRoute path="/zone/show/:id" component={zone_showScreen} />
                            {/* ORDERS */}
                            <ProtectedRoute path="/orders" component={Orders_ListScreen} />
                            <ProtectedRoute path="/order/show/:orderId" component={Orders_showScreen} />
                            {/* FOURNISSEUR*/}
                            <ProtectedRoute path="/fournisseurs" component={fournisseurs_listScreen} />
                            <ProtectedRoute path="/fournisseur/show/:idFournisseur" component={fournisseur_showScreen} />
                            <ProtectedRoute path="/fournisseur/add" component={fournisseur_addScreen} />
                            {/* ORDERS FOURNISSEUR */}
                            <ProtectedRoute path="/commandes_Allfournisseur" component={Orders_AllFournisseurs_commandes} />
                            <ProtectedRoute path="/commande-fournisseur/show/:idCommande" component={Orders_Fournisseurs_ShowScreen} />
                            <ProtectedRoute path="/commande-fournisseur/edit/:idCommande" component={Orders_Fournisseurs_EditScreen} />
                            <ProtectedRoute path="/commande-fournisseur/add" component={Orders_Fournisseurs_AddScreen} />
                            {/* DELIVERY TOWER */}
                            <ProtectedRoute path="/tour" component={DeliveryTower_ListScreen} />
                            {/* DELIVERY TOWER */}
                            <ProtectedRoute path="/CodePromo" component={CodePromo_ListScreen} />
                            {/* 404 NOT FOUND */}
                            <ProtectedRoute component={NotFound} />
                        </Switch>
                    </Layout>
                )} />
            </Switch>
        </BrowserRouter>
    )
}
export default Routes;
