// Importing necessary React and React Router dependencies
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Button,
  Row,
  Col,
  Container,
  Card
} from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import {
  saveProductData,
  fetchCategoryData,
  fetchBrandData,
  fechCategoriesPerParents,
} from "./DataService";
import OkModal from "../../components/modal/OkModal";
import LoadingModal from "../../components/modal/LoadingModal";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import { fetchBrandsDatano } from "../Brands/DataService";
import ImageUploder from "./ImageUploder";

// Component definition
const AddProductForm = () => {
  // State to hold the category data
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  // State to hold category data
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  // State to store options for marque and categorie
  const [marquesOptions, setMarquesOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  // Modal state
  const [showOkModal, setShowOkModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  // Action type state
  const [actionType, setActionType] = useState("");
  const [errors, setErrors] = useState({});
  // Use history
  const history = useHistory();
  const [fils, setFils] = useState([]);
  const [subCategorie, setSubCategorie] = useState([]);
  const [categorieParente, setCategorieParente] = useState(null);
  //****************************************************************************************/
  // State for product data and errors
  const [product, setProduct] = useState({
    // Initial product data
    titre: "",
    slug: "",
    description: "",
    reference: "",
    specification: "",
    conseil: "",
    ht: "",
    ttc: "",
    tva: "20",
    quantite: "",
    initial_quantity: "",
    status: "",
    marque: "",
    categorie: "",
    options: [],
  });
  // Effect to fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const Catdata = await fetchCategoryData();
        const BrandData = await fetchBrandData();
        setBrands(BrandData);
        setCategories(Catdata);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    fetchMarques();
    fetchCategories();
  // handleChoice();
  }, []);

  useEffect(() => {
    if (product.ht && product.tva) {
      const newTTC = calculateTTC(product.ht, product.tva);
  
      // Vérifiez si le TTC a réellement changé avant de mettre à jour l'état
      if (newTTC !== product.ttc) {
        setProduct((prevProduct) => ({
          ...prevProduct,
          ttc: newTTC,
        }));
      }
    }
  }, [product.ht, product.tva]);

  // Effectue la requête pour récupérer les options de marque
  const fetchMarques = async () => {
    try {
      const marques = await fetchBrandsDatano(); // Supposons que fetchMarquesOptions() retourne un tableau d'objets représentant les options de marque
      setMarquesOptions(marques); // Met à jour l'état avec les options de marque récupérées
    } catch (error) {
      console.error("Error fetching marques options:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      // Met à jour l'état avec les options de catégorie récupérées
      const Catdata = await fetchCategoryData();
      setCategories(Catdata);
      setSubCategorie(Catdata);
    } catch (error) {
      console.error("Error fetching categories options:", error);
    }
  };
  const renderSelectOptions = (categories, level = 0) => {
    return categories.map((category) => (
      <React.Fragment key={category?.id}>
        {level == 0 && (
          <option value={category?.id}>
            {" ".repeat(level * 2)}
            {category?.name}
          </option>
        )}
        {category.subcategories &&
          category?.subcategories?.length > 0 &&
          renderSelectOptions(category?.subcategories, level + 1)}
      </React.Fragment>
    ));
  };

  const renderSelectsubCategorie = (categories, level = 0) => {
    return categories.map((category) => (
      <React.Fragment key={category?.id}>
        {level > 0 && (
          <option value={category?.id}>
            {" ".repeat(level * 2)}
            {category?.name}
          </option>
        )}
        {category.subcategories &&
          category?.subcategories?.length > 0 &&
          renderSelectsubCategorie(category?.subcategories, level + 1)}
      </React.Fragment>
    ));
  };
  // Fonction pour gérer le choix de la catégorie
  const handleChoice = async (fieldName, value) => {
    setCategorieParente(value);
    try {
      const response = await fechCategoriesPerParents(value);
      if (response) {
        setSubCategorie(response?.data);
      }
    } catch (error) {
      // Step 5: Handle the error (display a message, log, etc.)
      console.error("Error saving product data:", error);
    }
  };
  //****************************************************************************************/
  // Function to handle OkModal close
  const handleOkModalClose = () => {
    setShowOkModal(false);
  };
  // Function to handle ConfirmationModal close
  const handleLoadingModalClose = () => {
    setShowLoadingModal(false);
  };
  // Function to handle modal exit
  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  //****************************************************************************************/
  // Function to handle input changes for regular fields

  const handleInputChange = (fieldName, value) => {
    setProduct({ ...product, [fieldName]: value });
  };

  const calculateTTC = (ht, tva) => {
    const htValue = parseFloat(ht) || 0; // Ensure HT is a number
    const tvaValue = parseFloat(tva) / 100 || 0; // Convert TVA to a percentage
    return (htValue * (1 + tvaValue)).toFixed(2); // TTC = HT * (1 + TVA) with two decimals
  };
  // Function to handle input changes for options
  const handleOptionChange = (index, field, value) => {
    const newOptions = [...product.options];
    newOptions[index] = { ...newOptions[index], [field]: value };
    setProduct({ ...product, options: newOptions });
  };
  // Function to add a new option
  const handleAddOption = () => {
    setProduct({
      ...product,
      options: [...product.options, { option: "", value: "" }],
    });
  };
  // Open save modal and set action type
  const handleSaveClick = () => {
    // Set the action type to 'delete'
    setActionType("save");
    // Show the confirmation modal before deleting
    setShowConfirmationModal(true);
  };
  //****************************************************************************************/
  // Function to handle form submission
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    handleCloseConfirmationModal();
    // Step 1: Validate required fields
    const requiredFields = [
      "titre",
      "slug",
      "description",
      "ht",
      "ttc",
      "tva",
      "quantite",
      "status",
      "quantite",
      "marque",
      "categorie",
    ]; // Add other required fields
    const newErrors = {};
    let isValid = true;

    requiredFields.forEach((field) => {
      if (!product[field]) {
        newErrors[field] = "Ce champ est obligatoire.";
        isValid = false;
      }
    });

    // If required fields are not filled, display errors and stop form submission
    if (!isValid) {
      setErrors(newErrors);
      return;
    }
    // Step 2: Clear previous errors if any
    setErrors({});
    setShowLoadingModal(true);

    const formData = new FormData();
    formData.append("titre", product.titre);
    formData.append("slug", product.slug);
    formData.append("reference", product.reference);
    formData.append("description", product.description);
    formData.append("specification", product.specification);
    formData.append("conseil", product.conseil);
    formData.append("tva", product.tva);
    formData.append("ht", product.ht);
    formData.append("ttc", product.ttc);
    formData.append("status", product.status);
    formData.append("quantite", product.quantite);
    formData.append("initial_quantity", product.initial_quantity);
    formData.append("marque", product.marque);
    formData.append("categorie", product.categorie);
    product.options.forEach((opt, index) => {
      formData.append(`options[${index}][option]`, opt.option);
      formData.append(`options[${index}][value]`, opt.value);
    });
    fils.length > 0 &&
      fils.forEach((file, index) => {
        formData.append(`files[]`, file);
      });
    // Step 3: Save product data
    try {
      const response = await saveProductData(formData);
      if (response) {
        setShowOkModal(true); // Show OkModal when save is successful
        history.push("/products"); // Update the path based on your application structure
      } else {
        setShowOkModal(true);
      }
    } catch (error) {
      // Step 5: Handle the error (display a message, log, etc.)
      console.error("Error saving product data:", error);
    } finally {
      // Step 6: Reset loading state and close confirmation modal
      setShowLoadingModal(false);
    }
  };

  //****************************************************************************************/
  // Render the component
  return (
    <Container>
      <div className="container">
        {/* Breadcrumbs */}
        <p className="h6">Accueil / Produits / Ajouter produit</p>

        {/* Header and filters */}
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            {/* Display dynamic title */}
            <h1 className="p-1">Ajouter produit</h1>
          </Col>
        </Row>

        <ImageUploder setFils={setFils} fils={fils} errors={errors} />
        {/* Form - Information generale */}
        <Card className="mb-3">
          <Card.Body>
            <p className="h6">Information générale</p>

            {/* Row 1 - Titre, Slug, Description, HT */}
            <Row>
              <Col>
                <Form.Group id="formTitre" className="pb-3">
                  <Form.Label>Titre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrer Titre"
                    value={product?.titre || ""}
                    onChange={(e) => handleInputChange("titre", e.target.value)}
                    isInvalid={!!errors["titre"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["titre"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group id="formSlug" className="pb-3">
                  <Form.Label>Slug</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrer Slug"
                    value={product?.slug || ""}
                    onChange={(e) => handleInputChange("slug", e.target.value)}
                    isInvalid={!!errors["slug"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["slug"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group id="formReference" className="pb-3">
                  <Form.Label>Référence</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrer Référence"
                    value={product?.reference || ""}
                    onChange={(e) =>
                      handleInputChange("reference", e.target.value)
                    }
                    isInvalid={!!errors["reference"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["reference"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group id="formStatus" className="pb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={product?.status || ""}
                    onChange={(e) =>
                      handleInputChange("status", e.target.value)
                    }
                    isInvalid={!!errors["status"]}
                  >
                    <option value="" disabled>
                      Sélectionner le status
                    </option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors["status"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group id="formQuantite" className="pb-3">
                  <Form.Label>Quantité</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Entrer Quantité"
                    value={product?.quantite}
                    onChange={(e) =>
                      handleInputChange("quantite", e.target.value)
                    }
                    isInvalid={!!errors["quantite"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["quantite"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group id="formInitialQuantity" className="pb-3">
                  <Form.Label>Quantité max par panier</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Entrer Initial Quantity"
                    value={product?.initial_quantity}
                    onChange={(e) =>
                      handleInputChange("initial_quantity", e.target.value)
                    }
                    isInvalid={!!errors["initial_quantity"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["quantite"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group id="formDescription" className="pb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Entrer Description"
                    value={product?.description || ""}
                    onChange={(e) =>
                      handleInputChange("description", e.target.value)
                    }
                    isInvalid={!!errors["description"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["description"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group id="formSpecification" className="pb-3">
                  <Form.Label>Spécification</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={10}
                    placeholder="Entrer Spécification"
                    value={product?.specification || ""}
                    onChange={(e) =>
                      handleInputChange("specification", e.target.value)
                    }
                    isInvalid={!!errors["specification"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["specification"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group id="formSpecification" className="pb-3">
                  <Form.Label>Conseil</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Entrer Conseil"
                    value={product?.conseil || ""}
                    onChange={(e) =>
                      handleInputChange("conseil", e.target.value)
                    }
                    isInvalid={!!errors["conseil"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["conseil"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group id="formHT" className="pb-3">
                  <Form.Label>Prix HT</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Entrer HT"
                    value={product?.ht}
                    onChange={(e) => handleInputChange("ht", e.target.value)}
                    isInvalid={!!errors["ht"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["ht"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {/* Row 2 - TTC, TVA, Quantite, Status */}

              <Col>
                <Form.Group id="formTTC" className="pb-3">
                  <Form.Label>Prix TTC</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Entrer TTC"
                    value={product?.ttc}
                    onChange={(e) => handleInputChange("ttc", e.target.value)}
                    isInvalid={!!errors["ttc"]}
                    readOnly
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["ttc"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/* <AutoCalculateTTCForProduct /> */}
              <Col>
                <Form.Group id="formTVA" className="pb-3">
                  <Form.Label>Code TVA</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Entrer TVA"
                    value={product?.tva}
                    onChange={(e) => handleInputChange("tva", e.target.value)}
                    isInvalid={!!errors["tva"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["tva"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {/* Row 3 - Initial Quantity, Marque ID, Category ID */}
            <Row>
              <Col>
                <Form.Group id="formMarqueId" className="pb-3">
                  <Form.Label>Marque</Form.Label>
                  <Form.Control
                    as="select"
                    id="brandSelect"
                    className="form-select"
                    onChange={(e) =>
                      handleInputChange("marque", e.target.value)
                    }
                    value={product.marque}
                  >
                    <option value="">Sélectionner une marque</option>
                    {marquesOptions?.map((marque) => (
                      <option key={marque.id} value={marque.id}>
                        {marque.name}
                      </option>
                    ))}
                  </Form.Control>
                  {selectedBrand && (
                    <Form.Text
                      id="passwordHelpBlock"
                      muted
                      title={selectedBrand.description}
                    >
                      Description: {selectedBrand.description.substring(0, 100)}
                      ...
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col>
                {/* Categorie Field */}
                <Form.Group controlId="formCategorie">
                  <Form.Label>Catégorie Parente</Form.Label>
                   <Form.Control
                    as="select"
                    className="form-select"
                    value={product?.categorieParente}
                    onChange={(e) =>
                      handleChoice("categorieParente", e.target.value)
                    }
                  >
                    <option>Sélectionner une catégorie</option>
                    {renderSelectOptions(categories)}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                {/* Categorie Field */}
                <Form.Group controlId="formsubCategorie">
                  <Form.Label>Catégorie</Form.Label>
                  <Form.Control
                    as="select"
                    className="form-select"
                    value={product.categorie}
                    onChange={(e) =>
                      handleInputChange("categorie", e.target.value)
                    }
                  >
                    <option value="">Sélectionner une catégorie</option>
                    {renderSelectsubCategorie(subCategorie)}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            {/* Row 4 - Save Button */}
            <Row>
              <Col>
                <Form.Group
                  className="mt-3 d-flex justify-content-end align-items-center"
                  id="formComment"
                >
                  <Button variant="primary" onClick={handleSaveClick}>
                    Enregistrer
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Additional Card for Options */}
        <Card className="mb-3">
          <Card.Body>
            <p className="h6">Options</p>
            {/* Add option-related form elements here */}
            {/* Options Section */}
            <div className="mt-4">
              {product?.options?.map((option, index) => (
                <div className="row pb-4" key={index}>
                  <Form.Group id={`option-${index}`} className="col-md-4 ">
                    <Form.Label>Option:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Entrer option"
                      value={option?.option}
                      onChange={(e) =>
                        handleOptionChange(index, "option", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group id={`value-${index}`} className="col-md-4">
                    <Form.Label>Valeur:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Entrer valeur"
                      value={option.value}
                      onChange={(e) =>
                        handleOptionChange(index, "value", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
              ))}
              <Button
                variant="primary"
                className="mt-2"
                onClick={handleAddOption}
              >
                Ajouter Option
              </Button>
            </div>
            {/* Row 4 - Save Button */}
            <Row>
              <Col>
                <Form.Group
                  className="mt-3 d-flex justify-content-end align-items-center"
                  id="formComment"
                >
                  <Button variant="primary" onClick={handleSaveClick}>
                    Enregistrer
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>

      {/* Ok Modal */}
      <OkModal
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
      />
      {/* Confirmation Modal with Spinner */}
      <LoadingModal show={showLoadingModal} onClose={handleLoadingModalClose} />
      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={
          actionType === "save" ? handleFormSubmit : "handleDeleteConfirm"
        }
        actionType={actionType}
      />
    </Container>
  );
};

// Export the component
export default AddProductForm;
