import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import ClickBack from "../../../components/buttons/ClickBack";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import ListCommandesFournisseur from "./ListCommandesFournisseur";
import ListFacturesFournisseur from "./ListFacturesFournisseur";
import { GetFournisseur, UpdateFournisseur } from "./DataService";
import EditFicheFournisseur from "../Component/EditFicheFournisseur";

function ShowFournisseur() {
  const [eventKeyValue, setEventKeyValue] = useState("informations");
  const [fournisseur, setFournisseur] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState("");

  const { idFournisseur } = useParams();
  const history = useHistory();

  const fetchOneFournisseur = async (idFournisseur) => {
    try {
      const fournisseurData = await GetFournisseur(idFournisseur);
      setFournisseur(fournisseurData.data);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const { error: apiError } = error.response.data.error;
        setApiError(apiError); // Set API error message
      } else {
        console.error("Error fetching fournisseur Data:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

    const initialValues = {
      initialAddress: fournisseur.adresse || "",
      initialaltitudeFournisseur: fournisseur.latitude || "",
      initiallongitudeFournisseur: fournisseur.longitude || "",
      initialEmail: fournisseur.email || "",
      initialRaisonSociale: fournisseur.RaisonSocial || "",
      initialStatus: fournisseur.etat || "",
      initialTel: fournisseur.TelFournisseur || "",
      initialMontantHT: fournisseur.montantLivraison || "",
      initialCommission: fournisseur.commission || "",
    };

  //Appel à Api pour récupérer liste des commandes par fournisseur
  const handleSubmit = async (values) => {
    console.log("values:",values)
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("etat", values.etat);
    formData.append("TelFournisseur", values.TelFournisseur);
    formData.append("adresse", values.address);
    formData.append("altitudeFournisseur", values.altitudeFournisseur);
    formData.append("longitudeFournisseur", values.longitudeFournisseur);
    formData.append("montantLivraison", values.montantHT);
    formData.append("commission", values.commission);
    formData.append("RaisonSocial", values.RaisonSocial);

    try {
      const response = await UpdateFournisseur(idFournisseur, formData);
        history.push("/fournisseurs");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const apiErrorMessage = error.response.data.error;
        setApiError(apiErrorMessage);
      } else {
        console.error("There was an error editing the fournisseur!", error);
      }
    }
  };

  //Fonction pour se déplacer entre les tabs apés avoir convertir une commande en facture
  useEffect(() => {
    if (idFournisseur) {
      fetchOneFournisseur(idFournisseur);
    }
  }, [idFournisseur,eventKeyValue]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Container>
      <div className="userList">
        <p className="h6">
          Accueil / Fournisseur / {fournisseur.RaisonSocial}
        </p>
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">
              Fournisseur : {fournisseur.RaisonSocial}
            </h1>
          </Col>
        </Row>
        <Tabs
          activeKey={eventKeyValue}
          onSelect={(key) => {
            setEventKeyValue(key);
          }}
          transition={true}
          id="noanim-tab-example"
          className="mb-3"
        >
          <Tab eventKey="informations" title="Informations">
           {fournisseur &&(
            <EditFicheFournisseur
              handleSubmit={handleSubmit}
              {...initialValues}
              apiErrors={{ email: apiError }}
            />         
           )} 
          </Tab>
          <Tab eventKey="commandes" title="Commandes">
            <ListCommandesFournisseur setEventKeyValue={setEventKeyValue} />
          </Tab>
          <Tab eventKey="factures" title="Factures">
            <ListFacturesFournisseur  eventKeyValue={eventKeyValue} />
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
}

export default ShowFournisseur;
