import React from 'react';
import LoginServices from './AuthServices';
import Logo from '../../images/Frame 1321317892.png';
import { Link } from "react-router-dom";
const LoginScreen = () => {

  const {
    loginState,
    setEmail,
    setPassword,
    handleLogin,
    status
  } = LoginServices();

  const { email, password } = loginState;

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center mainBgColor ">
      <div className="container-sm blurBG p-4 rounded" style={{ width: 500 }}>
        {/* Logo link */}
        <img className="img-fluid float-left " src={Logo} alt="LogoMarion" width='50%' />
        <hr></hr>
        <h1 className="text-left mainColor">Se connecter</h1>
        <form className='pt-3'>
          <div className="mb-3">
            <input
              type="text"
              placeholder='Adresse e-mail'
              className="form-control form-control-lg "
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder='Mot de passe:'
              className="form-control form-control-lg"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-3 text-end ">
            <a href="/reinitialiser_mot_de_passe" className='btn-cnx'>Mot de passe oublié ?</a>
          </div>
          <div className="d-grid gap-2">
            <button
              type="button"
              className="btn btn-primary btn-lg"
              onClick={handleLogin}
              disabled={status.loading}
            >
              {status.loading ? '...' : 'Je me connecte'}
            </button>
          </div>

          {status.error && (
            <div className="alert alert-danger mt-3" role="alert">
              {status.error}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
