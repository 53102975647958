import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // If you are using React Router

const NotFoundPage = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="text-center">
          <h1 className="display-1">404</h1>
          <p className="lead  p-2  border">Oops! Page non trouvée</p>
          <p className=' p-2 '>Désolé, la page que vous cherchez est peut-être ailleurs</p>
          {/* If you are using React Router, you can use the Link component */}
          <Link to="/">
            <Button variant="primary">Retour à l'accueil</Button>
          </Link>
          {/* If not using React Router, you can use a regular anchor tag */}
          {/* <a href="/">Go to Home</a> */}
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
