// ProtectedRoute.jsx
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const FournisseurRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = () => {
        const user = JSON.parse(localStorage.getItem("userData"));

        // Check if the user's role is "6"
        if (user?.role !== "6") {
            return false;
        }

        const authToken = localStorage.getItem("authToken");
        
        // Check if authToken is present and valid
        if (!authToken) {
            return false; // No token present
        }

        // Decode the token and check its structure
        const parts = authToken.split(".");
        if (parts.length !== 3) {
            return false; // Not a valid JWT structure
        }

        const decodedToken = jwtDecode(authToken);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

        // Check if token is expired
        if (decodedToken.exp && decodedToken.exp < currentTime) {
            return false; // Token is expired
        }

        return true; // User is authenticated
    };

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated() ? <Component {...props} /> : <Redirect to="/" />
            }
        />
    );
};

export default FournisseurRoute;
