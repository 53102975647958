 ///////////////////////////////// Ce fichier pour les fonction qui se répète par tous/////////////////////////////////////////
 
 // Fonction pour formater le nombre avec des séparateurs de milliers et arrondir à 2 chiffres après la virgule
 export const formatAllType = (value) => {
    let valueConvert = Number(value);
    return new Intl.NumberFormat('fr-FR', {  
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(valueConvert);  
  };

  //Cette Fonction pour formater la Date
  export const FormatageDate = (date) =>{
    const formattedDate = date.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }) + ' ' + date.toLocaleTimeString('fr-FR', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit', // Include seconds
      hour12: false // To ensure 24-hour format
    });
    return formattedDate ;
  }

  
  
 // Fonction pour la calcule de la montant TTC conçu pour le fournisseur
  export const CalculeTTC = (montantCommValue, htValue, commissionValue, tvaValue) => {
    let commission = (montantCommValue / 100) * commissionValue;
    let htC = commission + htValue  ;
    let tva = (htC / 100 ) *  tvaValue;
    let totalTTC = htC  + tva  ;
  
    return totalTTC;
  };




 









  
