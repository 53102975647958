// dataService.js
import { toast } from 'react-toastify';
import api from '../../../utils/ApiConfig';


// API pour Recupérer la liste fournisseur avec pagination 
const addDemandeCommandeFournisseur = async (data,history) => {
    try {
        const response = await api.post('fo/addDemandeCommandeFournisseur',data)
     //   api.post( `${urlPdf}/api/fo/addDemandeCommandeFournisseur`, data)
        .then((res)=>{     
            toast.success('Commande enregistré avec succès!', {
              position: 'top-right',
             autoClose: 3000,
             hideProgressBar: false,
             closeOnClick: true,
              pauseOnHover: true,
             draggable: true,
             onClose: () => {
                    history.push("/fournisseur/confirmation"); // Redirect to the confirmation page   
            },
            });
          
            
          
       // window.location.reload()
        })
        .catch((err)=>{
        console.log(err)
        toast.error('Échec. Veuillez réessayer.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        })
    } catch (error) {
        console.error('Error fetching');
        throw error;
    }
};


// vérification du token
const verifyToken = async (token) => {
    try {
        
        const response = await api.post('verificationToken',token);
        return response.data;
    } catch (error) {
        console.error('Error fetching');
        throw error;
    }
};







// Export methods *****************************************************************************************
export {addDemandeCommandeFournisseur,verifyToken}

