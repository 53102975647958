import React, { useEffect, useMemo, useState } from "react";
import DynamicTablecommandesFournisseur from "../../../components/DynamicTablecommandesFournisseur";
import {
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
  Row,
} from "react-bootstrap";
import { fetchAllBySupplier, updateStatusCommande } from "./DataService";
import { useParams } from "react-router-dom";
import Download from "../../../images/download.svg";
import { urlPdf } from "../../../utils/ApiConfig";


function FcturesFournisseur({eventKeyValue}) {
  const [searchTerm, setSearchTerm] = useState(""); //Initialisation du terme de la recherche
  const [data, setData] = useState([]); //Initialisation du terme de la recherche
  const [filtreDate, setFiltreDate] = useState("");
  const [nbr, setNbr] = useState(1);
  const { idFournisseur } = useParams();
  const [nbElement, setNbElement] = useState(10);

  const getOrdersBySupplier = async () => {
    try {
      const response = await fetchAllBySupplier(
        "bo/getFactureByFrounisseur",
        idFournisseur,
        nbr,
        filtreDate,
        searchTerm,
        nbElement
      );
      setData(response);
    } catch (error) {
      console.error("There was an error fetching  fournisseurs!", error);
    }
  };
  const handleStatusChange = async (newStatus, invoice) => {
    try {
      // Example API call to update payment status
      const response = await updateStatusCommande(
        "bo/changeStatuFacture",
        invoice,
        newStatus
      );
      // Optionally, refresh the data or update the state
      getOrdersBySupplier();
    } catch (error) {
      console.error("Error updating payment status:", error);
      // Handle error (e.g., show a notification)
    }
  };
  // Function to handle user role change
  const handleNombreElementParPage = (nbElement) => {
    setNbElement(nbElement); // Set the selected value
    switch (nbElement) {
      case 10:
        setNbElement(10);
        break;
      case 25:
        setNbElement(25);
        break;
      case 50:
        setNbElement(50);
        break;
      case 100:
        setNbElement(100);
        break;
      default:
        setNbElement(10);
    }
  };
  useEffect(() => {
    getOrdersBySupplier();
  }, [searchTerm, nbElement,eventKeyValue,nbr]);

  const columns = useMemo(
    () => [
      { Header: "Raison Social", accessor: "RaisonSocial" },
      { Header: "Date de facturation", accessor: "dateFacturation" },
      {
        Header: "Statut",
        accessor: "statuFacture",
        Cell: ({ row }) => (
          <Form.Group className="mb-3" style={{ minWidth: "120px" }}>
            <Form.Select
              className="form-control w-100"
              value={row.original.statuFacture}
              // className="form-select"
              onChange={(e) =>
                handleStatusChange(e.target.value, row.original.idFacture)
              }
            >
              <option value="1">Payée</option>
              <option value="2">Non payée</option>
              <option value="3">En retard</option>
            </Form.Select>
          </Form.Group>
        ),
      },
      { Header: "Adresse du fournisseur", accessor: "AdressePickUp" },
      { Header: "E-mail fournisseur", accessor: "emailFournisseur" },
      {
        Header: "Actions",
        accessor: "idFacture",
        Cell: ({ value, row }) => (
          <div>
            <a target="_blank"
              href={`${urlPdf}/api/getFactureFournisseur/${row.original.idFacture}`}
              className="p-2"
            >
              <img src={Download} width="20px" alt="Download" />
            </a>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      {/* Header and filters */}
      <Row className="pt-4 pb-2 align-items-center">
        <Col>
          {/* Display nombre d'element par page dans le dynamic table */}
          <Dropdown as={ButtonGroup} className="p-2 mb-2">
            <Dropdown.Toggle id="dropdown-basic" className="UserTypeDropdown">
              Nombre d'element à afficher: {nbElement}{" "}
              {/* Afficher la valeur actuelle */}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleNombreElementParPage(10)}>
                10
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleNombreElementParPage(25)}>
                25
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleNombreElementParPage(50)}>
                50
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleNombreElementParPage(100)}>
                100
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col
          xs
          lg="2"
          className="d-flex justify-content-end align-items-center"
          style={{ alignContent: "right" }}
        ></Col>
      </Row>

      {data && (
        <DynamicTablecommandesFournisseur
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          columns={columns}
          data={data?.data || []}
          link={data?.links}
          next_page_url={data?.next_page_url}
          prev_page_url={data?.prev_page_url}
          last_page={data?.last_page}
          setNbr={setNbr}
          nbr={nbr}
          total={data?.total}
          name="factures_fournisseurs"
          filtreDate={filtreDate}
        />
      )}
    </Container>
  );
}

export default FcturesFournisseur;
