// Importation des dépendances et composants nécessaires
import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Row, Col, Modal, Form, Button, Alert, Badge } from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import DynamicTable from "../../components/DynamicTable";
import Download from "../../images/download.svg";
import IconEdit from "../../images/edit.png";
import IconShow from "../../images/fi_eye.png";
import { deleteOrder, fetchOrdersData, updateOrder } from "./DataService";
import { format } from "date-fns";
import { urlPdf } from "../../utils/ApiConfig";
import { formatAllType } from "../../utilities";

function ListScreen(props) {
  // Initialisation des hooks nécessaires
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add"); // Ajouter un état pour suivre le mode de la modale
  const [orderData, setOrderData] = useState(null);
  const [nbr, setNbr] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderID, setSelectedOrderID] = useState(null);
  const [error, setError] = useState(null); // État pour le message d'erreur
  const [searchTerm, setSearchTerm] = useState("");
  // Fonction pour gérer le clic sur l'option de la modale
  const handleModalOptionClick = (orderID) => {
    // Mettre à jour l'identifiant de livraison sélectionné
    setSelectedOrderID(orderID);

    // S'assurer que l'ID de livraison et ses propriétés sont valides avant de continuer
    if (orderID && orderID.original) {
      // Enregistrer le nom de la livraison sélectionnée pour le débogage
      // Définir selectedOrder avec les données de la livraison sélectionnée
      setSelectedOrder(orderID.original);
    } else {
      // Si l'ID de livraison ou ses propriétés ne sont pas valides, réinitialiser selectedDelivery à null ou à un objet vide
      setSelectedOrder(null); // ou setSelectedOrder({})
    }
    // Puis procéder à la définition de showModal et modalMode
    setShowModal(true);
    setModalMode("edit"); // Définir le mode de la modale sur modification
  };
  // Fonction pour fermer la modale
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
    setError(null); // Effacer le message d'erreur lorsque la modale est fermée
  };
  const handleNewOrderChange = (fieldName, value) => {
    const numericValue =
      ["status", "etat_paiement", "etat_livraison"].includes(fieldName) &&
      !isNaN(Number(value))
        ? Number(value)
        : value;
    setSelectedOrder((prevOrder) => ({
      ...prevOrder,
      [fieldName]: numericValue,
    }));
  };
  // Fonction pour récupérer les données des commandes
  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await fetchOrdersData(nbr, searchTerm);
      setOrderData(data);

    } catch (error) {
      setError("Erreur lors de la récupération des données des commandes.");
      console.error(
        "Erreur lors de la récupération des données des commandes :",
        error
      );
    } finally {
      setLoading(false);
    }
  };
  // Fonction pour mettre à jour les données de livraison
  const handleUpdateOrder = async (event) => {
    // Implémentation pour la mise à jour des données du Tour de livraison
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("Numéro de commande", selectedOrder.reference);
      formData.append(
        "Adresse de livraison",
        selectedOrder.adresse_user.adresse
      );
      formData.append("Prix total", selectedOrder.ttc);
      formData.append("Statut de commande", selectedOrder.status);
      formData.append("Statut de paiement", selectedOrder.etat_paiement);
      formData.append("Statut de livraison", selectedOrder.etat_livraison);
      formData.append("id", selectedOrderID.original.id);
      const response = await updateOrder(
        selectedOrderID.original.id,
        selectedOrder
      );
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(
          `Échec de la mise à jour de la livraison. Statut : ${response.status}`
        );
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de la mise à jour de la livraison :", error);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer la suppression de livraison
  const handleOrderDelete = async (orderID) => {
    // Implémentation pour la suppression de la livraison
    try {
      const response = await deleteOrder(orderID);
      if (response.status === 200) {
        handleCloseModal();
        history.go(0);
      } else {
        throw new Error(
          `Échec de d'Annulation d'une commande. Statut : ${response.status}`
        );
      }
    } catch (error) {
      setError(error.message);
      console.error("Erreur lors de l'annulation d'une commande :", error);
    }
  };

  // useEffect pour charger automatiquement les données lorsque le composant est monté
  useEffect(
    (nbr) => {
      fetchData(nbr);
    },
    [nbr, searchTerm]
  );

  // Colonnes mémorisées pour le composant DynamicTable
  const columns = useMemo(
    () => [
      {
        Header: "Numéro de commande",
        accessor: "reference",
        maxWidth: 400,
        minWidth: 140,
        width: 200,
      },
      {
        Header: "Date de commande",
        accessor: "created_at",
        Cell: ({ value }) => (
          <span>
            {value ? format(new Date(value), "dd-MM-yyyy HH:mm") : ""}
          </span>
        ),
      },
      { Header: "Nom", accessor: "user.nom" },
      { Header: "Prénom", accessor: "user.prenom" },
      {
        Header: "Adresse de livraison",
        accessor: "adresse_user.adresse",
        Cell: ({ value }) => (
          <span>
            {value
              ? value.split(" ").slice(0, 5).join(" ") +
                (value.split(" ").length > 5 ? " ..." : "")
              : ""}
          </span>
        ),
      },
      {
        Header: "Prix total",
        accessor: "montant",
        Cell: ({ value }) => formatAllType(value),
      },
      {
        Header: "Statut de commande",
        accessor: "status",
        Cell: ({ value }) => {
          let statusLabel = "";
          let badgeClass = "bg-secondary"; // Default badge color

          switch (value) {
            case "1":
              statusLabel = "En cours de livraison";
              badgeClass = "bg-warning";
              break;
            case "2":
              statusLabel = "Livré";
              badgeClass = "bg-success";
              break;
            case "3":
              statusLabel = "En cours de préparation";
              badgeClass = "bg-info";
              break;
            case "4":
              statusLabel = "En cours de validation";
              badgeClass = "bg-warning";
              break;
            case "5":
              statusLabel = "Validé";
              badgeClass = "bg-primary";
              break;
            case "6":
              statusLabel = "Annulé";
              badgeClass = "bg-danger";
              break;
            default:
              statusLabel = "En cours de préparation";
              badgeClass = "bg-info";
          }

          return <Badge className={badgeClass}>{statusLabel}</Badge>;
        },
      },
      {
        Header: "Statut de paiement",
        accessor: "etat_paiement",
        Cell: ({ value }) => {
          let statusLabel = "";
          let badgeClass = "bg-secondary"; // Default badge color

          switch (value) {
            case "2":
              statusLabel = "Payé";
              badgeClass = "bg-success";
              break;
            default:
              statusLabel = "Non Payé";
              badgeClass = "bg-danger";
          }

          return <Badge className={badgeClass}>{statusLabel}</Badge>;
        },
      },
      // {
      //   Header: "Statut de livraison",
      //   accessor: "etat_livraison",
      //   Cell: ({ value }) => {
      //     let statusLabel = "";
      //     let badgeClass = "bg-secondary"; // Default badge color

      //     switch (value) {
      //       case "1":
      //         statusLabel = "En cours de préparation";
      //         badgeClass = "bg-info";
      //         break;
      //       case "2":
      //         statusLabel = "En cours de livraison";
      //         badgeClass = "bg-warning";
      //         break;
      //       case "3":
      //         statusLabel = "Livré";
      //         badgeClass = "bg-success";
      //         break;
      //       case "4":
      //         statusLabel = "Annulé";
      //         badgeClass = "bg-danger";
      //         break;
      //       default:
      //         statusLabel = "En cours de validation";
      //         badgeClass = "bg-warning";
      //     }

      //     return <Badge className={badgeClass}>{statusLabel}</Badge>;
      //   },
      // },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value, row }) => (
          <div>
            {/* Icône d'édition avec un gestionnaire de clic */}
            <a href={`order/show/${row.original.id}`} className="p-2">
              <img src={IconShow} width="20px" alt="Voir" />
            </a>
            <a
              href="#"
              className="p-2"
              onClick={() => handleModalOptionClick(row)}
            >
              <img src={IconEdit} width="20px" alt="Supprimée" />
            </a>
          </div>
        ),
      },
      {
        Header: "Facture",
        accessor: "facture", // Assurez-vous que cette propriété contient les données de la facture
        Cell: ({ value }) => {
          if (value && value.code_facture) {
            const pdfUrl = `${urlPdf}/factures/${value.code_facture}.pdf`;
            return (
              <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                <img src={Download} alt="Download" />
              </a>
            );
          } else {
            return "";
          }
        },
      },
    ],
    []
  );

  // Rendu du message de chargement pendant que les données sont récupérées
  if (!orderData) {
    return (
      <Container>
        <p>Chargement...</p>
      </Container>
    );
  }

  // Rendu du composant
  return (
    <Container>
      <div className="DeliveryList">
        {/* Navigation par breadcrumb */}
        <p className="h6">Accueil / Commandes</p>

        {/* En-tête et filtres */}
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack />
            {/* Afficher le titre dynamique */}
            <h1 className="p-1">Commandes</h1>
          </Col>
          <Col
            xs
            lg="2"
            className="d-flex justify-content-end align-items-center"
            style={{ alignContent: "right" }}
          ></Col>
        </Row>

        {/* Rendu du composant DynamicTable */}
        {loading ? (
          // Affichage du chargeur pendant que les données sont récupérées
          <p className="lead">Chargement...</p>
        ) : (
          <DynamicTable
            columns={columns}
            setSearchTerm={setSearchTerm}
            data={orderData?.data}
            link={orderData?.links}
            next_page_url={orderData?.next_page_url}
            prev_page_url={orderData?.prev_page_url}
            last_page={orderData?.last_page}
            setNbr={setNbr}
            nbr={nbr}
            total={orderData?.total}
            name="order"
          />
        )}
      </div>

      {/* Modale Bootstrap */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === "add"
              ? "Ajouter une nouvelle Commande"
              : "Modifier/Supprimer une Commande : " +
                (selectedOrderID ? selectedOrderID.original.id : "")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group controlId="Commande" className="mb-2">
                  <Form.Label>Numéro de commande :</Form.Label>
                  <input
                    type="text"
                    id="reference"
                    name="reference"
                    className="form-control"
                    value={modalMode === "edit" ? selectedOrder?.reference : ""}
                    onChange={(e) =>
                      handleNewOrderChange("reference", e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="montant" className="mb-2">
                  <Form.Label>Prix total :</Form.Label>
                  <input
                    type="text"
                    id="montant"
                    name="montant"
                    className="form-control"
                    value={modalMode === "edit" ? selectedOrder?.montant : ""}
                    onChange={(e) => {
                      handleNewOrderChange("montant", e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="commandeStatus" className="mb-2">
                  <Form.Label>Statut de commande :</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={modalMode === "edit" ? selectedOrder?.status : ""}
                    onChange={(e) =>
                      handleNewOrderChange("status", e.target.value)
                    }
                    required
                  >
                    <option value="" disabled>
                      Sélectionner le statut
                    </option>
                    <option value="1">En cours de livraison</option>
                    <option value="2">Livré</option>
                    <option value="3">En cours de préparation</option>
                    <option value="4">En cours de validation</option>
                    <option value="5">Validé</option>
                    <option value="6">Annulé</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="paiementStatus" className="mb-2">
                  <Form.Label>Statut de paiement :</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={
                      modalMode === "edit" ? selectedOrder?.etat_paiement : ""
                    }
                    onChange={(e) =>
                      handleNewOrderChange("etat_paiement", e.target.value)
                    }
                    required
                  >
                    <option value="1">Non payé</option>
                    <option value="2">Payé</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
              <Col md={6}>
                <Form.Group controlId="statut_Livraison" className="mb-2">
                  <Form.Label>Statut de livraison :</Form.Label>
                  <Form.Control
                    as="select"
                    name="etat_livraison"
                    value={
                      modalMode === "edit" ? selectedOrder?.etat_livraison : ""
                    }
                    onChange={(e) =>
                      handleNewOrderChange("etat_livraison", e.target.value)
                    }
                    required
                  >
                    <option value="" disabled>
                      Sélectionner le statut
                    </option>
                    <option value="1">En cours de préparation</option>
                    <option value="2">En cours de livraison</option>
                    <option value="3">Livré</option>
                    <option value="4">En Attente </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row> */}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          {modalMode === "edit" && (
            <Button
              variant="danger"
              onClick={() =>
                handleOrderDelete(
                  selectedOrderID ? selectedOrderID.original.id : ""
                )
              }
            >
              Annuler
            </Button>
          )}
          <Button variant="primary" onClick={handleUpdateOrder}>
            Enregistrer les modifications
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ListScreen;
