import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import ClickBack from "../../../components/buttons/ClickBack";
import moment from "moment";
import { GetOneCommandeById } from "./DataService"; // Remplacez par votre fonction d'appel API
import { urlPdf } from "../../../utils/ApiConfig";

function ShowCommandeFournisseur() {
  const [orderData, setOrderData] = useState();
  const { idCommande } = useParams();

  
  const fetchOrderData = async (idCommande) => {
    try {
      const order = await GetOneCommandeById(idCommande);
      
      setOrderData(order);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  //Fonction pour définir les status commande fournisseur
  const mapStatusCommande = (statusID) => {
    switch (statusID) {
      case 1:
        return "En cours de livraison";
      case 2:
        return "Livré";
      case 3:
        return "En cours de préparation";
      case 4:
        return "En cours de validation";
      case 5:
        return "Validé";
      case 6:
        return "Annulé";
      default:
        return "";
    }
  };
  //Fonction pour définir les status commande fournisseur
  const mapStatusPaiement = (statusID) => {
    switch (statusID) {
      case 1:
        return "Facturé";
      case 2:
        return "Non Facturé";
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchOrderData(idCommande);
  }, [idCommande]);

  if (!orderData) {
    return (
      <Container>
        <p>Loading...</p>
      </Container>
    );
  }

  return (
    <Container>
      <div className="orderDetails">
        <p className="h6">
          {/* Breadcrumb navigation */}
          Accueil / Commandes Fournisseurs
        </p>

        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">Commande Fournisseur Num ° {orderData?.id}</h1>
          </Col>
        </Row>

        <Card className="mb-3">
          <Card.Body>
            <Card.Title className="pb-3">Détails de la commande</Card.Title>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Fournisseur: </strong>
                  {
                    orderData?.fournisseur_profil?.user_profil?.RaisonSocial
                  }{" "}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Email Fournisseur: </strong>
                  {orderData?.emailFournisseur}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Téléphone fournisseur: </strong>
                  {orderData?.TelFournisseur}
                </p>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Nom Client: </strong>
                  {orderData?.NomClient}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Prénom Client: </strong>
                  {orderData?.PrenomClient}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Téléphone Client: </strong>
                  {orderData?.TelClient}
                </p>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Raison Social Client: </strong>
                  {orderData?.RaisonSocialClient}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Date de commande: </strong>
                  {moment(orderData?.DateCommande).format("DD-MM-YYYY HH:mm")}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Date de Livraisson: </strong>
                  {moment(orderData?.DateLivraison).format("DD-MM-YYYY HH:mm")}
                </p>
              </Col>
            </Row>

            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Livreur: </strong>
                  {orderData?.livreur_profil?.nom}{" "}
                  {orderData?.livreur_profil?.prenom}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Adresse Pick Up: </strong>
                  {orderData?.AdressePickUp}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Adresse Livraison: </strong>
                  {orderData?.AdresseLivraison}
                </p>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Statut de Paiement: </strong>
                  {mapStatusPaiement(orderData?.StatuPaiement)}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Statut de Commande: </strong>
                  {mapStatusCommande(orderData?.StatuCommande)}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Numéro Bon de Livraison : </strong>
                  {orderData?.NBonLivraison}
                </p>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Montant de la commande: </strong>
                  {orderData?.MontantCommande}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Tarif de la Livraison : </strong>
                  {orderData?.livraisonHT}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Commision : </strong>
                  {orderData?.commission}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  <strong>TVA: </strong>
                  {orderData?.TVA}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Montant TTC: </strong>
                  {orderData?.TTC}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Commentaire: </strong>
                  {orderData?.Commentaire || "-"}
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {orderData?.Bordereau ? (
          <Card className="mb-3">
            <Card.Body>
              <Card.Title className="pb-3">Bordereau:</Card.Title>
              <Row>
                <Col md={4}>
                  <Card>
                    <iframe
                      variant="top"
                      src={`${urlPdf}/Bordereau/${orderData?.Bordereau}`}
                      alt="Bordereau"
                    />
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : (
          <Card className="mb-3">
            <Card.Body>
              <Card.Title className="pb-3">Bordereau: -</Card.Title>
            </Card.Body>
          </Card>
        )}
      </div>
    </Container>
  );
}

export default ShowCommandeFournisseur;
