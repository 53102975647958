
import React, { useEffect, useState } from "react";
import Logo from "../../../images/Frame 1321317892.png";
import Eye from "../../../images/fi_eye.svg";
import EyeClosed from "../../../images/fi_eye-off.svg";
import ArrowRight from "../../../images/fi_arrow-right.svg";
import axios from "axios";
import { useParams } from "react-router-dom";
import { verifyToken } from "../demandeCommandeFournisseurs/DataService";
import { urlPdf } from "../../../utils/ApiConfig";

const FirstConnexion = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [valid, setValid] = useState(null); // null initially to avoid flicker on load
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const params = useParams();

  // Regex pattern for password validation
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;

  const validatePasswords = () => {
    if (!passwordPattern.test(password)) {
      setError(
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial."
      );
      return false;
    }
    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return false;
    }
    setError(""); // Clear error if everything is valid
    return true;
  };

  const VerifyTokenBefore = async (token) => {
    try {
      const res = await verifyToken({ token });
      setValid(res.valid);
    } catch (err) {
      console.error("Error verifying token", err);
      setError("Le lien est invalide ou a expiré.");
      setValid(false); // Set to false if there's an error in verification
    }
  };

  useEffect(() => {
    VerifyTokenBefore(params.token);
  }, [params.token]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validatePasswords()) {
      const data = {
        token: params.token,
        password: password,
        password_confirmation: confirmPassword,
      };

      axios
        .post(`${urlPdf}/api/updatePasswordF`, data)
        .then((res) => {
          // Save data to localStorage
          localStorage.setItem("authToken", res?.data?.token);
          localStorage.setItem("userData", JSON.stringify(res?.data?.user));

          localStorage.setItem("idUser", res?.data?.user?.id);
          localStorage.setItem("role", res?.data?.user?.role);

          // Check if response contains an error field and handle it
          if (res.data.error) {
            setError(res.data.error);
          } else {
            // Redirect to another route if no error
            window.location.href = "/fournisseur/commande";
          }
        })
        .catch((err) => {
          // Log detailed error object to understand the issue better
          console.error("Full error object:", err);

          // Set error message from response if available
          const errorMessage =
            err.response?.data?.error || "An unexpected error occurred.";
          setError(errorMessage);
        });
    }
  };

  const handleToggle = (type) => {
    if (type === "confirm") {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center mainBgColor">
      {valid === null && <div>Chargement...</div>} {/* Show loading */}
      {valid === false && (
        <div className="alert alert-danger">
          Le lien est invalide ou a expiré.
        </div>
      )}
      {valid && (
        <div className="container-sm blurBG p-4 rounded" style={{ width: 500 }}>
          <img
            className="img-fluid float-left"
            src={Logo}
            alt="LogoMarion"
            width="50%"
          />
          <hr />
          <h1 className="text-left mainColor">Définir ton mot de passe</h1>
          <form onSubmit={handleSubmit}>
            <div className="password-input-container form-floating mb-3 flex-grow-1">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder=""
                onChange={(event) => {
                  setPassword(event.target.value);
                  setError("");
                }}
                className="form-control"
              />
              <label htmlFor="password">Mot de passe</label>
              <div className="password-toggle-icon">
                {showPassword ? (
                  <img
                    src={Eye}
                    className="password-icon"
                    alt="password show"
                    onClick={() => handleToggle("password")}
                  />
                ) : (
                  <img
                    src={EyeClosed}
                    className="password-icon"
                    alt="password hide"
                    onClick={() => handleToggle("password")}
                  />
                )}
              </div>
            </div>
            <div className="password-input-container form-floating mb-3 flex-grow-1">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmpassword"
                name="confirmpassword"
                placeholder=""
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                  setError("");
                }}
                className="form-control"
              />
              <label htmlFor="confirmpassword">Confirmer le mot de passe</label>
              <div className="password-toggle-icon">
                {showConfirmPassword ? (
                  <img
                    src={Eye}
                    className="password-icon"
                    alt="password show"
                    onClick={() => handleToggle("confirm")}
                  />
                ) : (
                  <img
                    src={EyeClosed}
                    className="password-icon"
                    alt="password hide"
                    onClick={() => handleToggle("confirm")}
                  />
                )}
              </div>
            </div>
            <div>
              <button type="submit" className="btn btn-primary btn-lg w-100">
                Je me connecte{" "}
                <img src={ArrowRight} className="ml-8" alt="arrow right" />
              </button>
            </div>
            <span className="text-danger pt-5">{error}</span>
          </form>
        </div>
      )}
    </div>
  );
};

export default FirstConnexion;
