// dataService.js
import api from "../../../utils/ApiConfig";

//Fonction pour récupérer les commandes de tous les fournisseurs
const fetchAllFournisseurCommandes = async (nbr, termSearch, nbPage) => {
  try {
    let url = `/fo/ShowAllCommandeFournisseur?page=${nbr}`;
    if (termSearch) {
      url += `&${termSearch}`;
    }
    if(nbPage){
      url += `&nbpage=${nbPage}`;
  }
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching");
    throw error;
  }
};

//Fonction pour récupérer les commandes d'un Fournisseur
const fetchFournisseurCommandes = async (nbr, termSearch) => {
  try {
    const response = await api.get(`/...?page=${nbr}${termSearch}`);
  } catch (error) {
    console.error("Error fetching Orders Fournisseur data:", error);
    throw error;
  }
};

//Fonction pour Ajouter une Commande Fournisseur
const CreateFournisseurCommande = async (orderFournisseur) => {
  try {
    const response = await api.post(`/fo/addCommandeFournisseur`, orderFournisseur);
    return response;
  } catch (error) {
    console.error("Error saving Order Fournisseur data:", error);
    throw error;
  }
};

//Fonction pour Mettre à Jour une Commande Founisseur
const updateFournisseurCommande = async (orderId, orderData) => {
  try {
    const response = await api.post(`/fo/updateCommandeFournisseur/${orderId}`, orderData);
  } catch (error) {
    console.error("Error updating Order Fournisseur data:", error);
    throw error;
  }
};

//Fonction pour Archiver Liste des livreur
const FetchAllLivreur = async () => {
  try {
    const response = await api.get(`/bo/getAllLivreur`);
    return response;
  } catch (error) {
    console.error("Error to get  list livreur data:", error);
    throw error;
  }
};

//Fonction pour afficher liste fournisseur sans pagination 
const FetchListFournisseur = async () => {
  try {
    const response = await api.get(`/bo/getAllFournisseur`);
    return response;
  } catch (error) {
    console.error("Error to get  list fournisseur data:", error);
    throw error;
  }
}
 //Fonction pour afficher une commande fournisseur
 const GetOneCommandeById = async (idcommande) => {
  try{
  const response = await api.get(`/fo/ShowOneCommandeFournisseur/${idcommande}`);
  return response.data;
  }catch(error){
    console.error("Error to get Order fournisseur data:", error);
    throw error;
  }
 }

 

//  Export methods *****************************************************************
export {
  fetchFournisseurCommandes,
  CreateFournisseurCommande,
  updateFournisseurCommande,
  FetchAllLivreur,
  FetchListFournisseur,
  fetchAllFournisseurCommandes,
  GetOneCommandeById
};
